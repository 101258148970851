<template>
  <div>
    <el-drawer
      custom-class="form_drawer"
      :title="drawerTitle"
      size="80%"
      :visible.sync="formShow"
      @closeDrawer="closeDrawer"
      :modal-append-to-body="false">
      <Form :formConfig="formConfig"></Form>
    </el-drawer>
  </div>
</template>

<script>
// import CopyHandle from '@/found/views/process/components/copy_handle/copy_handle.vue';
import Form from '@/found/views/process/components/process_approve/approve.vue';

export default {
  name: 'audit',
  components: {
    Form,
    // CopyHandle,
  },
  props: {
    config: Object,
  },

  data() {
    return {
      formShow: false,
      formConfig: {},
      code: 'view',
      drawerTitle: '查看',
    };
  },
  created() {
    this.formConfig = {
      row: this.row,
      code: this.code,
    };
  },
  methods: {
    closeDrawer(name) {
      this[name] = false;
    },
  },
};
</script>

<style scoped>

</style>
