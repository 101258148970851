<template>
  <div>
    <a-modal
      :title="modalConfig.title"
      :visible="modalConfig.visible"
      :width="modalConfig.width || '1000px'"
      :destroyOnClose="true"
      :footer="null"
      centered
      :maskClosable="false"
      @cancel="close"
    >
      <el-scrollbar
        wrap-class="modal-scrollbar"
        :class="
          modalConfig.bottomPadding === false
            ? 'paddingbottom20'
            : 'dialog-style'
        "
        :style="{ height: modalConfig.height || '44vh' }"
        :native="false"
        view-class="modal-container"
        :noresize="false"
        tag="div"
      >
        <div v-if="isShow">
            <div v-for="(item) in showList" :key="item.businessNo">
                <div class="text-align font-size1">
                    <span>{{item.businessNo}}</span>:<span>{{item.resultDesc}}</span>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="text-align font-size">本次共批量<span class="font-size">{{modalConfig.code==='approval'?'通过':'驳回'}}</span><span class="coclor-red font-size">{{modalConfig.row.length}}</span>条客户汇款流程</div>
            <div class="text-align font-size">请点击确认以继续</div>
            <div class="title"><span v-if="modalConfig.code!=='approval'" class="coclor-red">*</span>审批意见</div>
            <el-input type="textarea" v-model="remarks" :rows="5" placeholder="请填写审批意见"></el-input>
        </div>
        <div v-if="modalConfig.showFooter" class="dialog-footer">
          <div  v-if="isShow">
            <el-button
              icon="el-icon-check"
              type="primary"
              @click="cancel"
              >关闭</el-button
            >
          </div>
          <div  v-else>
            <el-button
              icon="el-icon-check"
              type="primary"
              @click="onOk"
              >确定</el-button
            >
            <el-button
              icon="el-icon-close"
              type="danger"
              @click="close"
              >取消</el-button
            >
          </div>
        </div>
      </el-scrollbar>
    </a-modal>
  </div>
</template>

<script>
import { Loading } from 'element-ui';

export default {
  name: 'audit',
  components: {},
  props: {
    config: Object,
  },

  data() {
    return {
      modalConfig: {}, // 弹框信息
      remarks: '',
      isShow: false,
      showList: [],
    };
  },
  created() {
    this.modalConfig = {
      row: this.row,
      code: this.code,
      visible: true,
      showFooter: true,
      title: this.code === 'approval' ? '审批' : '驳回',
      width: '400px',
    };
    console.log(this.modalConfig, 'this.modalConfig');
  },
  methods: {
    close() {
      this.modalConfig.visible = false;
    },
    async onOk() {
      if (this.code !== 'approval' && !this.remarks) {
        this.$message.error('请填写审批意见！');
        return false;
      }
      let list = [];
      list = this.modalConfig.row.filter((v) => v.isTask === 'Y');
      let data = [];
      data = list.map((m) => {
        const obj = {};
        obj.businessNo = `${m.billCode}-${m.customerCode}`;
        obj.crmProcessInstanceId = m.crmProcessInstanceId;
        obj.pass = this.code === 'approval';
        obj.taskId = m.taskId;
        obj.remarks = this.remarks;
        return obj;
      });
      const params = data;
      const loadingInstance = Loading.service({
        fullscreen: true,
        text: '加载中...',
        background: 'rgba(0, 0, 0, 0.8)',
      });
      const { result } = await this.$http.post('/activiti/activitiController/completeTasksByList', params);
      this.$nextTick(() => {
        loadingInstance.close();
      });
      if (data.length <= 0) {
        this.modalConfig.visible = false;
        this.getParentTable();
      } else {
        this.isShow = true;
        this.showList = result || [];
      }
    },
    cancel() {
      this.modalConfig.visible = false;
      this.getParentTable();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-modal-body {
  padding: 0;
}
.dialog-style {
  padding-bottom: 73px;
}

.paddingbottom20{
  padding-bottom: 20px;
}
/deep/.modal-container {
  height: 100%;
}
/deep/.modal-scrollbar {
  overflow-x: hidden;
  padding: 20px;
}
.text-align{
    text-align:center;
}
.title{
    margin: 10px 0;
}
.coclor-red{
    color: red;
}
.font-size{
  font-size: 16px;
}
.font-size1{
  font-size: 14px;
}
</style>
