var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.modalConfig.title,
            visible: _vm.modalConfig.visible,
            width: _vm.modalConfig.width || "1000px",
            destroyOnClose: true,
            footer: null,
            centered: "",
            maskClosable: false,
          },
          on: { cancel: _vm.close },
        },
        [
          _c(
            "el-scrollbar",
            {
              class:
                _vm.modalConfig.bottomPadding === false
                  ? "paddingbottom20"
                  : "dialog-style",
              style: { height: _vm.modalConfig.height || "44vh" },
              attrs: {
                "wrap-class": "modal-scrollbar",
                native: false,
                "view-class": "modal-container",
                noresize: false,
                tag: "div",
              },
            },
            [
              _vm.isShow
                ? _c(
                    "div",
                    _vm._l(_vm.showList, function (item) {
                      return _c("div", { key: item.businessNo }, [
                        _c("div", { staticClass: "text-align font-size1" }, [
                          _c("span", [_vm._v(_vm._s(item.businessNo))]),
                          _vm._v(":"),
                          _c("span", [_vm._v(_vm._s(item.resultDesc))]),
                        ]),
                      ])
                    }),
                    0
                  )
                : _c(
                    "div",
                    [
                      _c("div", { staticClass: "text-align font-size" }, [
                        _vm._v("本次共批量"),
                        _c("span", { staticClass: "font-size" }, [
                          _vm._v(
                            _vm._s(
                              _vm.modalConfig.code === "approval"
                                ? "通过"
                                : "驳回"
                            )
                          ),
                        ]),
                        _c("span", { staticClass: "coclor-red font-size" }, [
                          _vm._v(_vm._s(_vm.modalConfig.row.length)),
                        ]),
                        _vm._v("条客户汇款流程"),
                      ]),
                      _c("div", { staticClass: "text-align font-size" }, [
                        _vm._v("请点击确认以继续"),
                      ]),
                      _c("div", { staticClass: "title" }, [
                        _vm.modalConfig.code !== "approval"
                          ? _c("span", { staticClass: "coclor-red" }, [
                              _vm._v("*"),
                            ])
                          : _vm._e(),
                        _vm._v("审批意见"),
                      ]),
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 5,
                          placeholder: "请填写审批意见",
                        },
                        model: {
                          value: _vm.remarks,
                          callback: function ($$v) {
                            _vm.remarks = $$v
                          },
                          expression: "remarks",
                        },
                      }),
                    ],
                    1
                  ),
              _vm.modalConfig.showFooter
                ? _c("div", { staticClass: "dialog-footer" }, [
                    _vm.isShow
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-check",
                                  type: "primary",
                                },
                                on: { click: _vm.cancel },
                              },
                              [_vm._v("关闭")]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-check",
                                  type: "primary",
                                },
                                on: { click: _vm.onOk },
                              },
                              [_vm._v("确定")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-close",
                                  type: "danger",
                                },
                                on: { click: _vm.close },
                              },
                              [_vm._v("取消")]
                            ),
                          ],
                          1
                        ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }