<script>
import TablePage from '@/found/components/table_page';
import Vue from 'vue';
import FormPage from '../form/form.vue';
import audit from '../audit/audit';
import Approval from '../approval/approval';

export default {
  extends: TablePage,
  components: {
    // Modal,
    FormPage,
    audit,
    Approval,
  },
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('bill-remittance-list');
  },
  methods: {
    clickVisible({
      row,
      val: { code },
    }) {
      const { approveStatus, ext3, processInstanceId } = row;
      // 审批通过 approved,审批中 approving,活动关闭 closed,流程追回 interrupt,驳回 rejected
      if (code === 'edit' && (approveStatus === '2' || approveStatus === '1')) {
        return false;
      }
      if (code === 'work-flow-deal' && !processInstanceId) {
        return false;
      }
      return true;
    },
    setColumn(v) {
      const rows = v;
      if (rows.field === 'companyName') { // 客户名称
        rows.isSearch = true;
        rows.apiUrl = '/mdm/companyInfo/companyPageList';
        rows.optionsKey = {
          label: 'companyName',
          value: 'companyCode',
        };
      }

      return rows;
    },
    modalClick({ val, row }) {
      const formPageArg = {
        drawerTitle: '',
        code: val.code, // 按钮对应code
        dataCode: '', // 数据code
        formShow: true,
      };
      switch (val.code) {
        case 'add':
          formPageArg.drawerTitle = '新增';
          this.createFormPage(formPageArg);
          break;
        case 'view':
          formPageArg.drawerTitle = '查看';
          formPageArg.id = row.id;
          this.createFormPage(formPageArg);
          break;
        case 'edit':
          formPageArg.drawerTitle = '编辑';
          formPageArg.id = row.id;
          this.createFormPage(formPageArg);
          break;
        case 'work-flow-deal':
          this.createAudit(formPageArg, row);
          break;
        case 'approval':
          formPageArg.drawerTitle = '审批';
          if (this.selectRow.length <= 0) {
            this.$message('最少选择一条数据操作');
            return false;
          }
          this.createApproval(formPageArg, this.selectRow);
          break;
        case 'reject':
          formPageArg.drawerTitle = '驳回';
          if (this.selectRow.length <= 0) {
            this.$message('最少选择一条数据操作');
            return false;
          }
          this.createApproval(formPageArg, this.selectRow);
          break;
        default:
          formPageArg.drawerTitle = '新增';
      }
    },
    createApproval(arg, row) {
      const that = this;
      const Component = Vue.extend(Approval);
      const vm = new Component({
        el: document.createElement('div'),
        data() {
          return {
            ...arg,
            row,
          };
        },
        methods: {
          getParentTable() {
            that.getConfigList('bill-remittance-list');
          },
        },
      });
      this.vm1 = vm;
      document.body.appendChild(vm.$el);
    },
    createAudit(arg, row) {
      const Component = Vue.extend(audit);
      const vm = new Component({
        el: document.createElement('div'),
        data() {
          return {
            ...arg,
            row: { ...row },
          };
        },
      });
      this.vm1 = vm;
      document.body.appendChild(vm.$el);
    },
    createFormPage(arg) {
      const that = this;
      const Component = Vue.extend(FormPage);
      const vm = new Component({
        el: document.createElement('div'),
        data() {
          return {
            ...arg,
          };
        },
        methods: {
          getParentTable() {
            that.getConfigList('bill-remittance-list');
          },
        },
      });
      document.body.appendChild(vm.$el);
    },
  },
};
</script>
